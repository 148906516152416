import React, { useState, useEffect } from 'react';

const BOARD_SIZE = 8;
const EMPTY = 0;
const BLACK = 1;
const WHITE = 2;

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    backgroundColor: '#f0f0f0',
    fontFamily: 'Arial, sans-serif',
  },
  title: {
    fontSize: '2rem',
    fontWeight: 'bold',
    marginBottom: '1rem',
  },
  score: {
    marginBottom: '1rem',
  },
  board: {
    display: 'grid',
    gridTemplateColumns: 'repeat(8, 1fr)',
    gap: '1px',
    backgroundColor: '#000',
    border: '1px solid #000',
  },
  cell: {
    width: '50px',
    height: '50px',
    backgroundColor: '#1a8f1a',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  piece: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
  },
  validMove: {
    width: '20px',
    height: '20px',
    borderRadius: '50%',
    backgroundColor: 'rgba(0, 255, 0, 0.3)',
  },
  gameStatus: {
    marginTop: '1rem',
    fontSize: '1.2rem',
  },
};

const Othello = () => {
  const [board, setBoard] = useState(() => {
    const initialBoard = Array(BOARD_SIZE).fill().map(() => Array(BOARD_SIZE).fill(EMPTY));
    initialBoard[3][3] = WHITE;
    initialBoard[3][4] = BLACK;
    initialBoard[4][3] = BLACK;
    initialBoard[4][4] = WHITE;
    return initialBoard;
  });
  const [currentPlayer, setCurrentPlayer] = useState(BLACK);
  const [gameOver, setGameOver] = useState(false);
  const [skippedTurn, setSkippedTurn] = useState(false);

  const getOpponent = (player) => (player === BLACK ? WHITE : BLACK);

  const isValidMove = (row, col, player) => {
    if (board[row][col] !== EMPTY) return false;

    const directions = [
      [-1, -1], [-1, 0], [-1, 1],
      [0, -1],           [0, 1],
      [1, -1],  [1, 0],  [1, 1]
    ];

    for (const [dx, dy] of directions) {
      let x = row + dx;
      let y = col + dy;
      let foundOpponent = false;

      while (x >= 0 && x < BOARD_SIZE && y >= 0 && y < BOARD_SIZE) {
        if (board[x][y] === EMPTY) break;
        if (board[x][y] === getOpponent(player)) {
          foundOpponent = true;
        } else if (foundOpponent) {
          return true;
        } else {
          break;
        }
        x += dx;
        y += dy;
      }
    }

    return false;
  };

  const flipPieces = (row, col) => {
    const directions = [
      [-1, -1], [-1, 0], [-1, 1],
      [0, -1],           [0, 1],
      [1, -1],  [1, 0],  [1, 1]
    ];

    const newBoard = [...board.map(row => [...row])];
    newBoard[row][col] = currentPlayer;

    for (const [dx, dy] of directions) {
      let x = row + dx;
      let y = col + dy;
      const piecesToFlip = [];

      while (x >= 0 && x < BOARD_SIZE && y >= 0 && y < BOARD_SIZE) {
        if (board[x][y] === EMPTY) break;
        if (board[x][y] === getOpponent(currentPlayer)) {
          piecesToFlip.push([x, y]);
        } else {
          for (const [fx, fy] of piecesToFlip) {
            newBoard[fx][fy] = currentPlayer;
          }
          break;
        }
        x += dx;
        y += dy;
      }
    }

    return newBoard;
  };

  const handleCellClick = (row, col) => {
    if (gameOver || !isValidMove(row, col, currentPlayer)) return;

    const newBoard = flipPieces(row, col);
    setBoard(newBoard);
    setCurrentPlayer(getOpponent(currentPlayer));
    setSkippedTurn(false);
  };

  const hasValidMove = (player) => {
    return board.some((row, i) =>
      row.some((_, j) => isValidMove(i, j, player))
    );
  };

  useEffect(() => {
    if (!hasValidMove(currentPlayer)) {
      if (skippedTurn) {
        setGameOver(true);
      } else {
        setCurrentPlayer(getOpponent(currentPlayer));
        setSkippedTurn(true);
      }
    }

    const blackCount = board.flat().filter(cell => cell === BLACK).length;
    const whiteCount = board.flat().filter(cell => cell === WHITE).length;

    if (blackCount + whiteCount === BOARD_SIZE * BOARD_SIZE) {
      setGameOver(true);
    }
  }, [board, currentPlayer, skippedTurn]);

  const renderCell = (value, row, col) => {
    let cellContent;
    if (value === EMPTY && isValidMove(row, col, currentPlayer)) {
      cellContent = <div style={styles.validMove} />;
    } else if (value === BLACK) {
      cellContent = <div style={{...styles.piece, backgroundColor: '#000'}} />;
    } else if (value === WHITE) {
      cellContent = <div style={{...styles.piece, backgroundColor: '#fff', border: '1px solid #000'}} />;
    }

    return (
      <div
        key={`${row}-${col}`}
        style={styles.cell}
        onClick={() => handleCellClick(row, col)}
      >
        {cellContent}
      </div>
    );
  };

  const blackCount = board.flat().filter(cell => cell === BLACK).length;
  const whiteCount = board.flat().filter(cell => cell === WHITE).length;

  const getWinner = () => {
    if (blackCount > whiteCount) return '黒';
    if (whiteCount > blackCount) return '白';
    return '引き分け';
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>オセロゲーム</h1>
      <div style={styles.score}>
        <span style={{marginRight: '1rem'}}>黒: {blackCount}</span>
        <span>白: {whiteCount}</span>
      </div>
      <div style={styles.board}>
        {board.map((row, i) =>
          row.map((cell, j) => renderCell(cell, i, j))
        )}
      </div>
      <div style={styles.gameStatus}>
        {gameOver ? (
          <p style={{fontWeight: 'bold'}}>
            ゲーム終了! {getWinner() === '引き分け' ? '引き分けです!' : `${getWinner()}の勝ちです!`}
          </p>
        ) : (
          <p>現在のプレイヤー: {currentPlayer === BLACK ? '黒' : '白'}</p>
        )}
      </div>
    </div>
  );
};

export default Othello;